export const INS_ETAT_EXAMEN_TYPE = {
	CREE: 1,
	EXPLOITABLE: 2,
	EXPLOITE: 3,
	DESACTIVE: 4
}

export const GENERIQUE_TYPE_EXAMEN_FCL = {
	PRO: 1,
	CFS: 2,
	PRI: 3,
	PRA: 4
}

export const ELS_EPREUVE_SESSION_ID_ETAT = {
	1: 'Créée',
	2: 'Sujet généré',
	3: 'Sujet validé',
	4: 'Corrigée'
}

export const TYPE_ALGORITHME = {
	DELAI_TENTATIVES: 1,
	DELAI_GLISSANT: 2
}

export const CHOIX_GRILLE = {
	GRILLE_100: 1,
	GRILLE_144: 2
}

export const ELS_SCRIPT_ETAT = {
	CREE: 1,
	VALIDE: 2,
	EXPLOITE: 3
}

export const TYPE_SITE_ECRAN = {
	0: 'Standard (fermé par défaut)',
	1: 'Standard (ouvert par défaut)',
	2: 'Compétences linguistiques VFR',
	3: 'Compétences linguistiques IFR',
	4: 'Compétences linguistiques mixte'
}

export const TYPE_SITE_ECRAN_ID = {
	STANDART_FERME: 0,
	STANDART_OUVERT: 1,
	VFR: 2,
	IFR: 3,
	MIXTE: 4
}

export const ETAT_SITE_ECRAN_ACTIVE = 1
export const ETAT_SITE_ECRAN_DESACTIVE = 2

export const ETAT_SITE_ECRAN = {
	1: 'Activé',
	2: 'Désactivé'
}

export const SITE_ECRAN_NON_STANDART_JOURNEE_TYPE = {
	FERMEE: { id: '5', name: 'Fermée' },
	VFR: { id: '6', name: 'Ouverte VFR' },
	IFR: { id: '7', name: 'Ouverte IFR' }
}

export const SITE_ECRAN_JOURNEE_TYPE = {
	FERMEE: { id: '2', name: 'Fermée' },
	RESERVEE: { id: '4', name: 'Réservée' },
	OUVERTE: { id: '5', name: 'Ouverte' }
}

export const TYPE_STATISTIQUES_CANDIDATS = {
	1: 'Candidats par examinateur (Pratique)',
	2: 'Taux de réussite (Théorique / Pratique)',
	3: 'Candidats ajournés (Pratique)',
	4: 'Candidats par organisme (Théorique / Pratique)',
	5: 'Candidats par centre (Théorique)'
}

export const TYPE_STATISTIQUES_COMPETENCES_LINGUISTIQUES = {
	1: 'Nombre d\'examens par niveau et par site',
	2: 'Moyenne des notes par code et par site'
}


export const ELS_ETAT_EPREUVE_SESSION = {
	CREEE: 1,
	SUJET_GENERE: 2,
	SUJET_VALIDE: 3,
	CORRIGEE: 4
}

export const RES_ID_TYPE_SANCTION_EPREUVE = {
	EPREUVE: '1',
	EXAMEN: '2',
	CANDIDAT: '3'
}

export const BNQ_ID_ORIGINE_QUESTION = {
	FRANCAISE: 1,
	EUROPEENNE: 2
}

export const BNQ_ID_ETAT_QUESTION = {
	CREEE: 1,
	SUPPRIMEE: 2,
	REFUSEE: 3,
	ACCEPTEE: 4,
	APPLICABLE: 5,
	EXPLOITEE: 6,
	HISTORISEE: 7
}

export const BNQ_ID_TYPE_ACTION = {
	REFUS: 1,
	SUPPRESSION: 2,
	RESTAURATION: 3,
	ACCEPTATION: 4,
	APPLICATION: 5
}

export const ORIGINE_PAIEMENT = {
	FRANCAISE: 1,
	EUROPEENNE: 2
}

export const ETAT_PAIEMENT = {
	NON_PAYEE: 1,
	PRE_PAYEE: 2,
	PAYEE: 3
}

export const BNQ_ETAT_VERSION_BANQUE = {
	KO: 0,
	OK: 1,
	PLANIFIEE: 2
}

export const ETAT_PRESENCE_LABEL = {
	1: 'Présent',
	2: 'Absent'
}

export const ETAT_PRESENCE = {
	PRESENT: 1,
	ABSENT: 2
}

export const INS_ETAT_INSCRIPTION_EPREUVE = {
	1: 'Initialisée',
	2: 'Autorisée',
	3: 'Refusée',
	4: 'Présence',
	5: 'Pré-absence',
	6: 'Absence',
	7: 'Réussie',
	8: 'Echouée',
	9: 'Panne',
	10: 'Fraude'
}

export const ORG_SURVEILLANT_EPREUVE_SITE_SESSION = {
	TRUE: 'Oui',
	FALSE: ''
}

export const BNQ_ETAT_IMPORT_KSA = {
	EN_ATTENTE: 1,
	EN_COURS: 2,
	REUSSI: 3,
	ECHOUE: 4
}